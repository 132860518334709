<template>
  <div class="config-modalidade new-tabs">
    <vertical-tabs-components :tabs="tabs">
      <div :slot="`tab-content-${tabs[0].tab}`">
        <el-alert
          title="Aviso"
          type="info"
          :closable="false"
           description="Preencha todos os campos obrigatórios para garantir o funcionamento correto do sistema. As definições nesta área afetam diretamente o processo de renovação de apólices e a experiência do usuário, incluindo notificações"
          show-icon
        >
        </el-alert>
        <div class="vx-row">
          <vs-divider color="dark"><b>Configuração</b></vs-divider>

          <div class="vx-col w-full">
            <span class="mt-3">Modalidade <b style="color:red">*</b></span>
            <el-select
              class="mt-3"
              v-model="modalidadeSelecionada.ModalidadeId"
              filterable
              placeholder="Escolha a modalidade"
            >
              <el-option
                v-for="item in modalidades"
                :key="item.Id"
                :label="item.Name"
                :value="item.Id"
              >
              </el-option>
            </el-select>
          </div>

          <vs-col vs-lg="4" class="mt-5 pl-3">
            <span style="    font-weight: bold;     font-size: 12px;"
              >Máximo de vigência da proposta em dias</span
            >
            <vs-input
              class="w-full mt-3  input-50"
              style="height:60px"
              v-model="modalidadeSelecionada.QtdMaximaDiasVigencia"
            />
          </vs-col>
          <vs-col vs-lg="4" class="mt-5 pl-3">
            <span style="font-weight: bold; font-size: 12px;"
              >Tempo para geração da proposta antes do final da vigência em
              dias</span
            >
            <vs-input
              class="w-full mt-3  input-50"
              style="height:60px"
              v-model="modalidadeSelecionada.QtdDiasGeracaoPropostaEmailAviso"
            />
          </vs-col>
          <vs-col vs-lg="4" class="mt-5 pl-3 pr-3">
            <span style="font-weight: bold; font-size: 12px;"
              >“Dias” Cancelamento Automático apólice anterior</span
            >
            <vs-input
              class="w-full mt-3  input-50"
              style="height:60px"
              v-model="modalidadeSelecionada.QtdDiasApoliceCancelada"
            />
          </vs-col>

          <vs-col vs-lg="12" class="mt-5">
            <span style="    font-weight: bold; font-size: 12px;"
              >Escolha o modelo de emissão</span
            ><br /><br />
            <el-radio @change="radioChange()" v-model="radio" label="1"
              >Anterior</el-radio
            >
            <el-radio @change="radioChange()" v-model="radio" label="2"
              >Posterior</el-radio
            >
          </vs-col>
          <vs-col vs-lg="6" class="mt-5 pl-3 pr-2">
            <span style="    font-weight: bold; font-size: 12px;"
              >“Dias” para Emissão da Apólice anterior ao final de
              vigência</span
            >
            <vs-input
              :disabled="radio != '1'"
              class="w-full mt-3  input-50"
              style="height:60px"
              v-model="modalidadeSelecionada.QtdDiasEmissaoAvisoAnterior"
            />
            <hr v-if="radio == '1'" class="line-radio" />
          </vs-col>
          <vs-col vs-lg="6" class="mt-5 pl-2 pr-3">
            <span style="    font-weight: bold; font-size: 12px;"
              >“Dias” para Emissão da Apólice posterior ao final de
              vigência</span
            >
            <vs-input
              :disabled="radio != '2'"
              class="w-full mt-3  input-50"
              style="height:60px"
              v-model="modalidadeSelecionada.QtdDiasEmissaoAvisoPosterior"
            />
            <hr v-if="radio == '2'" class="line-radio" />
          </vs-col>

          <vs-divider color="dark"><b>Flags</b></vs-divider>
          <vs-col vs-lg="12" class="mt-5">
            <div class="flex mb-3">
              <vs-switch
                class="mr-3 "
                v-model="modalidadeSelecionada.EmissaoAutomatica"
              />
              <label>Emissão Automática</label>
            </div>
            <div class="flex mb-3">
              <vs-switch
                class="mr-3 "
                v-model="modalidadeSelecionada.CancelarApoliceOriginal"
              />
              <label>Cancelamento de Apólice original</label>
            </div>
            <div class="flex mb-3">
              <vs-switch
                class="mr-3 "
                v-model="modalidadeSelecionada.ISReajuste"
              />
              <label>Reajuste de IS pelo Índice monetário</label>
            </div>
          </vs-col>
          <vs-col vs-lg="12" v-if="modalidadeSelecionada.ISReajuste">
            <vs-col vs-lg="6">
              <span class="mt-3"
                >Tipo de Índice <b style="color:red">*</b></span
              >
              <el-select
                class="mt-3"
                v-model="modalidadeSelecionada.IndiceId"
                filterable
                placeholder="Escolha o tipo"
              >
                <el-option
                  v-for="item in indiceList"
                  :key="item.Id"
                  :label="item.Nome"
                  :value="item.Id"
                >
                </el-option>
              </el-select>
            </vs-col>

            <vs-col vs-lg="6  pl-5" v-if="modalidadeSelecionada.IndiceId == 3">
              <span class="mt-3"
                >Índice percentual % <b style="color:red">*</b></span
              >
              <vs-input
                class="w-full mt-3 input-50"
                style="height:60px"
                v-model="modalidadeSelecionada.IndiceOutros"
              />
            </vs-col>
          </vs-col>
        </div>
        <vs-row vs-type="flex" vs-justify="end" class="mt-5">
          <vs-col
            vs-type="flex"
            vs-justify="end"
            vs-align="center"
            vs-lg="4"
            vs-sm="12"
          >
            <el-button round @click="voltar()" style="margin-right: 10px"
              >Voltar</el-button
            >
            <vs-button
              class="button-primary text-center"
              @click="update()"
              v-if="modalidadeSelecionada.ModalidadeId != null"
            >
              Salvar Alterações
            </vs-button>
            <vs-button class="button-primary text-center" v-else disabled>
              Salvar Alterações
            </vs-button>
          </vs-col>
        </vs-row>
      </div>
    </vertical-tabs-components>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb.vue";
import VerticalTabsComponents from "@/components/vertical-tabs/VerticalTabsComponents.vue";
import renovacaoController from "@/providers/renovacao-provider.js";
export default {
  components: {
    Breadcrumb,
    VerticalTabsComponents
  },
  data() {
    return {
      radio: "1",
      modalidadeSelecionada: {
        Id: 0,
        ModalidadeId: null,
        IndiceId: 1,
        IndiceOutros: null,
        QtdMaximaDiasVigencia: null,
        QtdDiasGeracaoPropostaEmailAviso: null,
        QtdDiasEmissaoAvisoAnterior: null,
        QtdDiasEmissaoAvisoPosterior: null,
        ISReajuste: false,
        EmissaoAutomatica: false,
        CancelarApoliceOriginal: false,
        QtdDiasApoliceCancelada: null,
        Ativo: true
      },
      indiceSelecionada: null,
      Status: false,
      modalidades: [],
      indiceList: [
        {
          NomeIndice: "IPCA",
          IndiceId: 1
        },
        {
          NomeIndice: "SELIC",
          IndiceId: 2
        }
      ]
    };
  },
  computed: {
    tabs() {
      return [
        {
          label: "Dados da Modalidade",
          tab: "Dados da Modalidade",
          disabled: false
        }
      ];
    }
  },
  mounted() {},
  async created() {
    await this.ObterModalidades();
    await this.ObterIndice();
  },
  methods: {
    voltar() {
      this.$router.push({
        name: "param-list-renovacao"
      });
    },
    radioChange() {
      if (this.radio === "1") {
        this.modalidadeSelecionada.QtdDiasEmissaoAvisoPosterior = 0;
      } else if (this.radio === "2") {
        this.modalidadeSelecionada.QtdDiasEmissaoAvisoAnterior = 0;
      }
    },
    async ObterModalidades() {
      await this.$onpoint.loading(async () => {
        return await renovacaoController
          .ObterModalidadesAtivas()
          .then(response => {
            this.modalidades = response;
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    async ObterIndice() {
      await this.$onpoint.loading(async () => {
        return await renovacaoController
          .ObterIndices()
          .then(response => {
            this.indiceList = response;
            let outros = {
              Nome: "OUTROS",
              Id: 3
            };
            this.indiceList.push(outros);
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    },
    async update() {
      await this.$onpoint.loading(async () => {
        return await renovacaoController
          .AtualizarParametroRenovacao(this.modalidadeSelecionada)
          .then(response => {
            this.$notify({
              title: "Success",
              message: "Dados atualizados com sucesso!",
              type: "success"
            });
            this.$router.push({
              name: "param-list-renovacao"
            });
          })
          .catch(error => {
            this.$onpoint.errorModal(error.response.data.Errors);
          });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.branch-container {
  position: relative;
  background: #fff;
  box-shadow: 0px 3px 10px 8px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  margin-bottom: 24px;
  margin-top: 25px;

  .title {
    padding: 32px 16px;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 138% */

    /* grayscale/gray-4 */

    color: #8e8e8e;

    /* Inside auto layout */

    flex: none;
    order: 0;
    flex-grow: 0;
  }

  .modality-group-content:not(:last-of-type) {
    border-bottom: 1px solid #e4e1e1;
  }

  .modality-group-content:not(:first-of-type) {
    padding-top: 16px;
  }

  .modality-group-content {
    margin: 0 16px 16px;
    padding-bottom: 16px;

    .modality-group-title {
      justify-content: space-between;
      padding-bottom: 28px;
      padding-top: 0px;
    }

    .data-content {
      color: #50555a;
      font-size: 14px;
      font-weight: 700;
    }

    .data-title {
      color: #8e8e8e;
      font-size: 12px;
      font-weight: 700;
    }
  }
}

.card-gray {
  background: #f7f7f7;
  color: #636363;
  font-weight: bold;
  border-radius: 10px;
  border: 1px solid #63636321;
  padding: 25px 15px;
  display: flex;

  .left,
  .right {
    width: 50%;
  }

  .left {
    text-align: left;
  }

  .right {
    display: flex;
    flex-direction: row-reverse;
  }
}

.main-card {
  padding: 15px 25px;
}

.history {
  position: absolute;
  top: -65px;
  right: 0;
}
.line-radio {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgba(var(--vs-primary), 1) !important;
  margin: 0em 0;
  padding: 0;
}
</style>
